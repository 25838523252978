// User profile, notifications & settings

.user-header{
  margin-bottom: $margin-sm;

  @include breakpoint(medium){
    margin-bottom: $margin-m;
  }
}

.user-header__main{
  @include clearfix;

  display: flex;
  align-items: center;
}

.user-header__avatar{
  width: 60px;

  > img{
    border-radius: 50%;
    width: 40px;
  }
}

.user-header__heading{
  margin-bottom: 0;
}

.user-nickname{
  color: gray;
  font-size: 1rem;

  .user-contact_link{
    margin-left: .5rem;
  }
}

.badge-card__content{
  @extend .card__content;

  display: flex;
  align-items: center;

  .icon{
    height: 1.5em;
    width: 1.5em;
    margin-right: .7em;
    vertical-align: middle;
  }
}

.profile--sidebar--title{
  font-size: 1.25rem;
}
