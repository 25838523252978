@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:
    url('fonts/decidim/Source_Sans_Pro_400.woff2') format('woff2'),
    url('fonts/decidim/Source_Sans_Pro_400.woff') format('woff'),
    url('fonts/decidim/Source_Sans_Pro_400.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src:
    url('fonts/decidim/Source_Sans_Pro_600.woff2') format('woff2'),
    url('fonts/decidim/Source_Sans_Pro_600.woff') format('woff'),
    url('fonts/decidim/Source_Sans_Pro_600.ttf') format('truetype');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src:
    url('fonts/decidim/Source_Sans_Pro_900.woff2') format('woff2'),
    url('fonts/decidim/Source_Sans_Pro_900.woff') format('woff'),
    url('fonts/decidim/Source_Sans_Pro_900.ttf') format('truetype');
}
