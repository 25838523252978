@import "quill/dist/quill.snow";
@import "stylesheets/decidim/variables";
@import "stylesheets/decidim/utils/settings";

.editor-container{
  margin-bottom: 1.5rem;

  p{
    line-height: $paragraph-lineheight;
    margin-bottom: $paragraph-margin-bottom;
    text-rendering: $paragraph-text-rendering;
  }
}
