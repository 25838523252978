.areachart{
    //common
    svg{
      background-color: darken($light-gray, 2.5);
      overflow: visible;
      border-radius: 3px;
    }

    .title{
      fill: $muted;
      font-weight: 600;
    }

    .area{
        fill: rgba($primary, .2);;
    }

    .line{
      fill: none;
      stroke: rgba(var(--primary-rgb), .2);
      stroke-width: 4px;
    }

    .circle{
      fill: rgba(var(--primary-rgb), .2);
    }

    .tick{
      opacity: .5;

      .dashed{
        stroke-dasharray: 1, 3;
      }
    }

    .sum{
      font-weight: 600;
      font-size: rem-calc(map-get(map-get(map-get($header-styles, medium), 'h1'), 'font-size')) * 1.5;
    }

    // color-dependent
    $map: $foundation-palette;

    @each $key, $value in $map{
      &.#{$key}{
        .area{
          fill: rgba($value, .05);
        }

        .line{
          stroke: rgba($value, .2);
        }

        .circle{
          fill: rgba($value, .2);
        }
      }
    }

    // variations
    &.small{
      .sum{
        font-size: rem-calc(map-get(map-get(map-get($header-styles, medium), 'h3'), 'font-size'));
      }
    }
  } 
