//Common

.main-footer,
.mini-footer{
  color: $medium-gray;

  a{
    color: darken($medium-gray, 40%);

    &:hover{
      color: darken($medium-gray, 20%);
      text-decoration: underline;
    }

    &:focus{
      outline-color: var(--highlight-alternative);
    }
  }
}

//Main footer
.main-footer{
  position: relative;
  padding: 1rem 0;
  min-height: 76px;
  background-color: #0a0a0a;
}

.main-footer__badge{
  display: block;
  width: 140px;
  text-align: center;

  @include breakpoint(large){
    position: absolute;
  }
}

@media print, screen and (min-width: 64em) {
  .main-footer__badge {
    position: relative;
    text-align: left;
  }
}

.main__footer__nav{
  text-align: center;

  @include breakpoint(medium){
    text-align: left;
  }

  @include breakpoint(large){
    text-align: center;
  }
}

.footer-nav{
  list-style: none;
  padding-top: .5rem;
  margin: 0 0 0 -1rem;

  li{
    display: inline-block;
    padding: 0 1rem;
  }

  @include breakpoint(large){
    margin-left: 0;
  }
}

.main__footer__social{
  text-align: center;

  @include breakpoint(medium){
    text-align: right;
  }
}

.footer-social{
  list-style: none;
  padding-top: .5rem;
  margin: 0;

  li{
    display: inline-block;
    padding-left: 1rem;

    &:first-child{
      padding-left: 0;
    }
  }
}

.footer-social__icon .icon{
  width: 16px;
  height: 16px;
}

//Mini footer
.mini-footer{
  background-color: #0a0a0a;
  padding: 1rem 0;
}

.mini-footer .row {
  display: flex;
  align-items: center;
  justify-content: center;

  .decidim-logo {
    display: flex;
    flex-grow: 0;
    width: auto;
    margin: 0 .5rem;
  }

  img {
    height: 1rem;
    margin-top: -0.25rem;
  }

  .mf-text {
    font-size: 80%;
    line-height: 1rem;

    a {
      font-weight: 600;

      &:hover {
        color: white;
      }
    }
  }
}