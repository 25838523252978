// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "email/email-custom";
@import "modules/footer";

.social-register {
  margin-bottom: 1rem;
  text-align: center;
}

.primary.external-link-container {
  color: var(--secondary)
}

a.button--france_connect {
  display: block;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
  max-width: 230px;
}
a.button--france_connect:hover {
  background-color: transparent;
  filter: brightness(120%);
}
